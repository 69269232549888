/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const tl = gsap.timeline({
    defaults: {
        duration: .6
    }
})

var animations = {

    parallax(el,trigger) {
        gsap.to(el, {
            scrollTrigger: {
                trigger: trigger,
                start: "top top",
                scrub: true
            },
            marker: false,
            y: 100
        
        })
    },

    animateX(el,trigger) {
        tl.from(el, {
            scrollTrigger: {
                trigger: trigger
            },
            x: -50,
            stagger: 0.6,
            opacity: 0
        })
    },

    animateY(el,trigger) {
        tl.from(el, {
            scrollTrigger: {
                trigger: trigger
            },
            y: 50,
            stagger: 0.6,
            opacity: 0
        })
    }
}

export default animations