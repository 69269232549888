<template>
    <figure class="quotation">
        <Image :imageSrc="imageSrc" :imageAlt="imageAlt" class="mb-5" />
        <blockquote>
            <p v-html="blockquote" />
        </blockquote>
        <hr>
        <figcaption>
            <p v-html="author" class="mb-1 title"/>
            <p v-html="subline" />
        </figcaption>
    </figure>
   
</template>

<script>
// @ is an alias to /src
import Image from '@/components/Elements/Image.vue'

export default {
    name: 'ImageTop',
    components: {
        Image
    },
    props: {
        imageSrc: String,
        imageAlt: String,
        blockquote: String,
        author: String,
        subline: String
    }
}
</script>