<template>
  <transition
        appear
        @enter="enter">
      <header class="header sticky py-5">
        <div class="container-fluid">
          <div class="columns is-mobile is-align-content-space-between is-vcentered">
            <div class="column py-0">
              <Logo class="logo" />
            </div>
            <div class="column py-0 has-text-right">
              <a v-if="buttonLink" :href="buttonLink" class="button px-5 is-primary is-icon-arrow has-text-blue" target="_blank">{{buttonText}}</a>
              <Button v-else :buttonText="buttonText" class="px-5 is-primary is-icon-arrow has-text-blue" scrollTarget="#formTarget" />
            </div>
          </div>
        </div>
        <span class="header--bg"></span>
      </header>
  </transition>
</template>

<script>
import Button from '@/components/Button.vue'
import Logo from '@/components/Logo.vue'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Header',
  props: {
    buttonText: String,
    buttonLink: String
  },
  components: {
    Button,
    Logo
  },
   /* eslint-disable no-unused-vars */
  setup() {
    const enter = (el) => {

      var changeColor = gsap.to('.header--bg', { height: '100%' })

      ScrollTrigger.create({

        trigger: '.subheader',
        start: () => (document.querySelector('.subheader').clientHeight - el.clientHeight) + " top",



        animation: changeColor,

        scrub: true,
        markers: false

      });

    }

    return { enter };
  }
  /* eslint-enable no-unused-vars */
}
</script>