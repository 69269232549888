<template>
    <div class="file is-small">
        <label class="file-label" :for="fieldname">
            <input :id="fieldname" :name="fieldname" class="custom-file-input input-file file-input" type="file" multiple="" :required="required" @change="previewFiles" tabindex="-1">
            <span class="file-cta">
                <span class="file-icon">
                    <i :class="fieldicon"></i>
                </span>
                <span class="file-label" ref="label">   
                    {{filelabel}}
                </span>
            </span>
        </label>
        <p class="help is-block pl-3">{{fieldlabel}}</p>
    </div>
</template>

<script>
export default {
    name: 'Fileupload',
    props: {
        fieldname: String,
        fieldlabel: String,
        required: String
    },
    data() {
        return {
            filelabel: 'Choose a file…',
            fieldicon: 'is-icon-upload'
        }
    },
    methods: {
        previewFiles(event) {
            const filename = event.target.files[0].name;
            this.filelabel = filename
            this.fieldicon = 'is-icon-check'
        }
    }
}

</script>