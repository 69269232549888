<template>
    <div class="box has-text-centered" :class="isAnimated ? 'is-animated' : ''">
        <div class="text">
            <p v-html="headline" class="title is-5" />
            <p v-html="content" class="mb-0" />
            <footer>
                <Button :buttonText="buttonText" scrollTarget="#formTarget" class="is-white has-text-primary px-5" />
            </footer>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    name: 'Box',
    components: {
        Button
    },
    props: {
        buttonText: String,
        headline: String,
        content: String,
        isAnimated: Boolean
    }
}
</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/elements/box.scss";
</style>