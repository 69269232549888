<template>
    <div v-show="formSent">
        <p>Dear {{first_name}} {{last_name}},<br>thank you very much for your interest in OMMAX and applying to our open position.</p>
        <p>We will check your documents carefully and individually and get back to you as soon as possible. The review and coordination with our departments may take a few days. Thank you for your patience!</p>
        <p>Best regards,<br>Your OMMAX-Team</p>
    </div>
<article class="message is-danger mb-0" v-show="formError">
  <div class="message-header">
    <p>{{statusText}}</p>
  </div>
</article>
    <form id="jobApplication" class="form-horizontal" method="POST" @submit.prevent="checkForm" enctype="multipart/form-data" v-if="formSent!=1">
    <h5 class="title" v-if="job_position_label">{{job_position_label}}</h5>
        <div class="columns is-mobile is-multiline">
            <div class="column is-12">
                <label for="formGender" class="label">Gender</label>
                <div class="control">
                    <div class="select">
                        <select name="gender" id="formGender" size="1" v-model="gender" required="required">
                            <option value="">Please choose *</option>
                            <option value="male">male</option>
                            <option value="female">female</option>
                            <option value="diverse">third gender</option>
                        </select>
                    </div>
                </div>
                <div class="error is-hidden">Please choose your gender</div>
            </div>
            <div class="column is-6">
                <div class="control">
                    <input type="text" id="formFirstName" required="required" name="first_name" class="input" v-model="first_name" placeholder="First name *">
                    
                </div>
                <div class="error is-hidden">Please enter your firstname</div>
            </div>
            <div class="column is-6">
                <div class="control">
                    <input type="text" id="formLastName" required="required" name="last_name" class="input" v-model="last_name" placeholder="Last name *">
                    
                </div>
                <div class="error is-hidden">Please enter your lastname</div>
            </div>
            <div class="column is-12">
                <div class="field">
                    <div class="control">
                        <input type="email" id="formEmail" required="required" name="email" class="input" v-model="email" placeholder="E-Mail *">
                    </div>
                    <div class="error is-hidden">Please enter a valid email address</div>
                </div>
            </div>
            <div class="column is-12">
            <p><strong>Please upload your application documents (max. 20MB in total)</strong></p>
            </div>
            <div class="column is-12 fileupload">
                <Fileupload fieldname="documents[cv]" fieldlabel="Resume *" required="required" />
            </div>
            <div class="column is-12 fileupload">
                <Fileupload fieldname="documents[employment-reference]" fieldlabel="Transcripts & Certificates" />   
            </div>
            <div class="column is-12 fileupload">
                <Fileupload fieldname="documents[other]" fieldlabel="Other documents (eg. letters of recommendation)" />
            </div>
            <div class="column is-12 checkbox">
                <label class="inlinecheckbox" for="formPrivacyPolicy">
                <input type="checkbox" name="privacyPolicy" id="formPrivacyPolicy" value="1" v-model="privacyPolicy"> Note: The data you provide will be treated confidentially and only used for your application process. You can find more information about privacy <a href="https://www.ommax-digital.com/en/privacy-policy/" target="_blank">here.</a></label>
                <div class="error is-hidden" id="errorPrivacyPolicy">Please confirm that you accept the privacy policy</div>
            </div>
            <div class="column is-12 formbuttons">
                <!-- <button form="jobApplication" id="resetform" class="sec" type="reset">Cancel</button> -->
                <input type="submit" value="Submit" class="button is-uppercase has-text-weight-bold scrollTo px-5 is-primary is-icon-arrow has-text-blue">
            </div>
        </div>
        <input name="access_token" type="hidden" :value="access_token">
        <input name="company_id" type="hidden" :value="company_id">
        <input name="job_position_id" type="hidden" :value="job_position_id">
        <input name="recruiting_channel_id" type="hidden" :value="recruiting_channel_id">
    </form>
</template>

<script>
import Fileupload from '@/components/Elements/Fileupload.vue'

export default {
  name: 'Personio',
  props: {
    job_position_id: Number,
    job_position_label: String,
    recruiting_channel_id: Number
  },
  components: {
      Fileupload
  },
  data() {
        return {
            formSent: 0,
            formError: 0,
            statusText: '',
            first_name: '',
            last_name: '',
            access_token: 'cb6390d6f314dcb9f1bc',
            company_id: '41940',
            privacyPolicy: true,
            email: '',
            gender: ''
        }
  },
  methods: {
    checkForm: function () {
        var form = document.getElementById('jobApplication');

        const formData = new FormData(form);
        const apiUrl = 'https://api.personio.de/recruiting/applicant'
        const options = {
            method: 'POST',
            body: formData
        }
        this.formError = 0

        fetch(apiUrl, options)
        .then(async res => {
            if(res.status==200){
                this.formSent = 1
                window.dataLayer.push({
                    event: "form_submitted",
                    form_category: "jobApplication"
                });
            } else {
                this.formError = 1
                this.statusText = res.status+' '+res.statusText
            }
        })
    }
  }
}

</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/elements/form.scss";
// @import "@/styles/elements/subheader.scss";
</style>
