<template>
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NP464G&gtm_preview=env-89&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <router-view/>
  <footer class="section has-background-blue is-medium">
    <p class="has-text-centered">
      <Button buttonText="To Top" scrollTarget="#top" class="is-text is-icon-arrow has-text-white scrolltop" />
    </p>
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column has-text-centered has-text-grey is-size-7">
          <ul class="nolist">
            <li class="">© 2023 OMMAX - Digital Solutions</li>
            <li class=""><a href="https://www.ommax-digital.com/en/imprint/" target="_blank">Imprint</a></li>
            <li class=""><a href="https://www.ommax-digital.com/en/privacy-policy/" target="_blank">Privacy Policy</a></li>
            <li class=""><a href="https://www.ommax-digital.com/en/terms-of-use/" target="_blank">Terms of Use</a></li>
            <li><a href="https://www.linkedin.com/company/ommax/" target="_blank" class="is-icon-linkedin"></a></li>
          </ul>          
        </div>
      </div>
    </div>
  </footer>
</template>

<script>



export default {
  name: 'App'
}

</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>