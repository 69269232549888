<template>
    <section class="section has-background-blue">
        <div class="container">
            <h3 class="title has-text-centered"><slot name="header" /></h3>
            <slot name="images" />
        </div>
    </section>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Collage',
    components: {
    },
    props: {
    }
}
</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/elements/textmedia.scss";
@import "@/styles/elements/collage.scss";

</style>