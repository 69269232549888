<template>

    <header class="section subheader has-signet has-background-image has-background-blue">
      <span class="section--shadow-left has-animation-opacity"></span>
      <span class="section--signet has-animation-x"></span>
      <div class="container">
        <div class="columns">
          <div class="column is-5-desktop is-8-tablet">
            <h1 v-html="headline" class="title is-3 has-animation" />
            <p v-html="text" class="is-size-4-tablet has-animation" />
            <Button :buttonText="buttonText" scrollTarget="#video" class="has-animation pl-0 is-text has-icon has-text-primary is-icon-play" />
          </div>
        </div>
      </div>

      <Button :buttonText="scrollDownText" scrollTarget="#intro" class="is-text is-icon-arrow has-text-white scrolldown" />

      <Image :imageSrc="image" imageAlt="OMMAX MBA" class="bg is-parallax" />
    </header>

</template>

<script>
import Image from '@/components/Elements/Image.vue'
import Button from '@/components/Button.vue'

import animations from '@/scripts/animations.js'

export default {
  name: 'Subheader',
  components: {
      Image,
      Button
  },
  props: {
    headline: String,
    text: String,
    buttonText: String,
    scrollDownText: String,
    image: String
  },
  /* eslint-disable no-unused-vars */
  setup() {
    const enter = (el) => {
      animations.parallax('.is-parallax', '.is-parallax');
      animations.animateX('.has-animation-x', el);
      animations.animateY('.has-animation', el);
    }
    return { enter };
  }
  /* eslint-enable no-unused-vars */
}

</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/elements/subheader.scss";
</style>
