<template>
  <main class="page" id="top">
    <Header buttonText="Apply now"/>
    <Subheader
      headline="Ommax Digital Mba – Accelerate Your Digital&nbsp;Career"
      text="Join the leading digital strategy consultancy in Europe"
      buttonText="See video now"
      scrollDownText="Get more information"
      image="ommax-mba-header.jpg"/>

  <Textmedia
    headline="Why ommax digital MBA?"
    buttonText="Apply now"
    class=""
    image="mba-andrea.jpg"
    imageAlt="OMMAX MBA Andrea"
    content="<ul><li>Become a thought leader in digital strategy and transformation</li><li>Build up an international network of colleagues and clients</li><li>Strengthen your consulting skills on projects across industries</li><li>Work with like-minded individuals in a young and dynamic atmosphere</li><li>Expand your perspectives for a digital leadership role</li></ul>"
    imgSize="is-4"
    txtSize="is-7"
    id="intro"
  />

  <section class="section is-large has-background-white-ter-half">
    <div class="container">
      <h2 class="title has-text-centered is-3">OMMAX DIGITAL MBA MODULES</h2>
      <div class="columns box--counted">
        <div class="column is-4">
          <Box buttonText="Apply now" headline="Digital Transaction Advisory" content="Advise leading private equity firms and strategic investors in making the right investment decision in e-commerce, tech and digital services." isAnimated />
        </div>
        <div class="column is-4">
          <Box buttonText="Apply now" headline="Digital Strategy Consulting" content="Define digital growth strategies incl. execution roadmaps to create sustainable value for SMEs as well as large enterprises across industries." isAnimated />
        </div>
        <div class="column is-4">
          <Box buttonText="Apply now" headline="Digital Allrounder" content="Combine best of both worlds and develop your digital skillset on transactions as well as digital strategy projects." isAnimated />
        </div>
      </div>
    </div>
  </section>

  <section class="section is-large has-background-white-ter pt-0">
    <div class="container">
      <h3 class="title mb-6">LEARNING FROM OMMAX EXPERT TEAMS</h3>
      <div class="columns is-multiline">
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Advanced Data Analytics"
              class=""
              imageSrc="analytics.png"
              imageAlt="Advanced Data Analytics"
              imageClass="mb-4"
              content="Enabling data-driven decision making and performance optimization along the entire customer journey."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Content &amp; Marketing Automation"
              class=""
              imageSrc="automation.png"
              imageAlt="Content Marketing Automation"
              imageClass="mb-4"
              content="Personalizing and automating cross-channel customer journeys to optimize conversion, retention and share of wallet."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="E-Commerce &amp; Marketplaces"
              class=""
              imageSrc="ecommerce.png"
              imageAlt="E-Commerce Marketplaces"
              imageClass="mb-4"
              content="Developing best-in-class strategies around “how to (cross-) sell products / services online” and digital go-to-market."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Digital Acquisition"
              class=""
              imageSrc="acquisition.png"
              imageAlt="Digital Acquisition"
              imageClass="mb-4"
              content="Leveraging social media and online advertising to generate new leads and build long-lasting customer relationships."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Experience Design"
              class=""
              imageSrc="xd.png"
              imageAlt="Experience Design"
              imageClass="mb-4"
              content="Enhancing all elements of the customer experience from initial awareness to conversion and advocacy."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Tech Development"
              class=""
              imageSrc="tech-development.png"
              imageAlt="Tech Development"
              imageClass="mb-4"
              content="Securing the foundation for all kinds of growth initiatives through tailored tech and app solutions."
            />
        </div>
      </div>
      <p class="mt-6">
        <Button buttonText="Apply now" scrollTarget="#formTarget" class="px-5 is-primary has-text-blue is-icon-arrow" />
      </p>
    </div>
  </section>

  <section class="section has-background-blue has-signet" id="video">
    <span class="section--shadow-left"></span>
    <span class="section--signet"></span>
    <h4 class="title has-text-centered mb-6">ACCELERATE YOUR<br>DIGITAL CAREER</h4>
    <div class="container">
      <figure class="video">
        <video controls poster="@/assets/video-poster.jpg">
          <source src="@/assets/OMMAX-Digital-Career.mp4" type="video/mp4">
        </video>
      </figure>
      <p class="has-text-centered mt-4">
        <Button buttonText="Apply now" scrollTarget="#formTarget" class="is-text is-icon-arrow is-underlined pl-0 has-text-white mt-3" />
      </p>
    </div>
  </section>

  <section class="section is-large">
    
    <div class="container is-max-widescreen">
      <h2 class="title has-text-centered is-3 mb-6">OMMAX Voices</h2>
      <div class="columns is-7 is-variable">
        <div class="column is-4 has-text-centered">
            <Quotation
              headline="Why ommax digital MBA?"
              class=""
              imageSrc="ommax-voices-12.jpg"
              imageAlt="Why OMMAX MBA"
              blockquote="“I joined OMMAX because of its digital capabilities and great team spirit.“"
              author="Alexander"
              subline="Senior Consultant"
            />
            <a href="https://www.linkedin.com/in/dr-alexander-r%C3%BChr-107a097b/" target="_blank" class="is-icon-linkedin mt-3"></a>
        </div>
        <div class="column is-4 has-text-centered">
            <Quotation
              headline="Why ommax digital MBA?"
              class=""
              imageSrc="ommax-voices-16.jpg"
              imageAlt="Why OMMAX MBA"
              blockquote="“At OMMAX, I am able to build an international network of digital natives.“"
              author="Andrea"
              subline="Manager"
            />
            <a href="https://www.linkedin.com/in/andrea-maria-simon/" target="_blank" class="is-icon-linkedin mt-3"></a>
        </div>
        <div class="column is-4 has-text-centered">
            <Quotation
              headline="Why ommax digital MBA?"
              class=""
              imageSrc="ommax-voices-4.jpg"
              imageAlt="Why OMMAX MBA"
              blockquote="“We are entrepreneurs and invest personally in the best businesses we see in the market.“"
              author="Florian"
              subline="Partner"
            />
            <a href="https://www.linkedin.com/in/florianewald/" target="_blank" class="is-icon-linkedin mt-3"></a>
        </div>
      </div>
    </div>
  </section>

 <section class="section is-large has-background-white-ter">
    <div class="container">
      <h3 class="title mb-6">What makes us unique</h3>
      <div class="columns is-multiline is-variable is-6-desktop">
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="DIGITAL EXPERTISE"
              class=""
              imageSrc="expertise.png"
              imageAlt="DIGITAL EXPERTISE"
              imageClass="mb-4"
              content="Build in-depth expertise in digital, data and technology."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="LEADING CLIENTS"
              class=""
              imageSrc="clients.png"
              imageAlt="LEADING CLIENTS"
              imageClass="mb-4"
              content="Work with leading private equity firms globally and C-level of large enterprises and mid-size companies."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="INDIVIDUAL CAREERS"
              class=""
              imageSrc="careers.png"
              imageAlt="INDIVIDUAL CAREERS"
              imageClass="mb-4"
              content="Gain various career perspectives: partner track, expert track, digital unit within corporate / SME and tech startup."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="INVESTMENT OPPORTUNITIES"
              class=""
              imageSrc="investment.png"
              imageAlt="INVESTMENT OPPORTUNITIES"
              imageClass="mb-4"
              content="Co-invest in leading e-com and tech players and participate financially in digital value creation."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="DOWNTOWN CAMPUS"
              class=""
              imageSrc="campus.png"
              imageAlt="DOWNTOWN CAMPUS"
              imageClass="mb-4"
              content="Work 100% from central office in downtown Munich (Schwabing) and not Monday to Thursday at the client site."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="PROFESSIONAL TRAININGS"
              class=""
              imageSrc="trainings.png"
              imageAlt="PROFESSIONAL TRAININGS"
              imageClass="mb-4"
              content="Grow personally through various professional trainings by leading coaches."
            />
        </div>
      </div>
    </div>
  </section>

  <section class="section is-large">
    
    <div class="container is-max-widescreen">
      <h3 class="title has-text-centered is-3 mb-6">Exemplary project work</h3>
      <div class="columns has-border-between is-6 is-variable">
        <div class="column is-4">
            <ImageTop
              headline="Commercial Due Diligence"
              class=""
              imageSrc="perfect-dive.png"
              imageAlt="Commercial Due Diligence"
              imageClass="mb-6"
              content="Sale of Perfect Drives Sports Group by Afinum Management to Bregal.<br>&nbsp;"
            />
            <a href="https://www.ommax-digital.com/en/insights/newsroom/ommax-advised-afinum-on-the-successful-sale-of-perfect-drive-sports-group-to-bregal-unternehmerkapital/" target="_blank" class="button is-uppercase has-text-weight-bold is-underlined scrollTo is-text pl-0 has-text-blue">
              Read more
            </a>
        </div>
        <div class="column is-4">
            <ImageTop
              headline="Digital Strategy Development"
              class=""
              imageSrc="medifox.png"
              imageAlt="Digital Strategy Development"
              imageClass="mb-6"
              content="Development of digital marketing ecosystem and performance analytics platform"
            />
            <a href="https://www.ommax-digital.com/en/insights/newsroom/successful-collaboration-between-medifox-and-ommax/" target="_blank" class="button is-uppercase has-text-weight-bold is-underlined scrollTo is-text pl-0 has-text-blue">
              Read more
            </a>
        </div>
        <div class="column is-4">
            <ImageTop
              headline="Digital Due Diligence<br>&nbsp;"
              class=""
              imageSrc="pflegehelden.png"
              imageAlt="Digital Due Diligence"
              imageClass="mb-6"
              content="Acquisition of Plegehelden Group by Rigeto<br>&nbsp;"
            />
            <a href="https://www.ommax-digital.com/en/insights/newsroom/ommax-advised-group-of-investors-led-by-rigeto-unternehmerkapital-on-the-successful-acquisition-of-pflegehelden-group/" target="_blank" class="button is-uppercase has-text-weight-bold is-underlined scrollTo is-text pl-0 has-text-blue">
              Read more
            </a>
        </div>
      </div>
    </div>
  </section>

  <section class="section has-background-blue mb-6">
    <div class="container">
      <h3 class="title has-text-centered">Leading clients</h3>
      <div class="columns is-multiline is-mobile is-centered">
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="ai-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="hg-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="apax-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="kkr-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="silverlake-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
      </div>
    </div>
  </section>

  <Textmedia
    headline="What you are responsible for"
    class=""
    image="mba-responsible.jpg"
    imageAlt="OMMAX MBA - What you are responsible for"
    content="<ul><li>Advise financial (PEs) and strategic investors in company transactions with a focus on commercial, digital and technology questions.</li><li>Develop digital go-to-market and/or transformation strategies incl. market dynamics, competitive landscape, customer acquisition and technical infrastructure.</li><li>Align and manage project requests and analyses closely with OMMAX internal digital expert teams.</li><li>Identify client consulting needs, structure the problem and independently develop solutions.</li><li>Prepare presentations for client projects and business development purposes.</li><li>Take part in and lead activities such as workshops, meetings, research, stakeholder engagement for both client and internal projects.</li></ul>"
    imgSize="is-6-desktop is-5-tablet"
    txtSize="is-5-desktop is-6-tablet"
  />

  <Textmedia
    headline="What you can expect"
    class=""
    image="expect.jpg"
    imageAlt="OMMAX MBA - What you can expect"
    isImageRight
    content="<ul><li>Make a real difference and be a part of a growing, motivated and creative team directly collaborating with the OMMAX founders/partners.</li><li>Benefit from a wide range of opportunities for personal and professional growth and a great learning atmosphere.</li><li>Benefit from a variety of valuable experiences and insights into new digital business models and best practices across different industries.</li><li>Take over responsibility for challenging and exciting projects and clients in an international environment.</li><li>Enjoy food and beverages in the office, sport sessions and team events as well as other additional benefits.</li><li>Become part of a family-friendly company with home office possibility, located in Munich, Berlin and Hamburg.</li><li>Receive a market competitive salary and bonus based on individual and company performance</li></ul>"
    imgSize="is-6-desktop is-5-tablet"
    txtSize="is-5-desktop is-6-tablet"
  />

  <Textmedia
    headline="What we require"
    buttonText="Apply now"
    class="mb-6"
    image="require.jpg"
    imageAlt="OMMAX MBA - What we require"
    content="<ul><li>University degree in business, economics, computer sciences or a related discipline with outstanding academic grades.</li><li>Master degree (junior entry role) or at least 2-3 years of relevant experience (senior entry role) with focus on (digital) strategy consulting, transaction advisory or digital marketing.</li><li>High analytical ability and technology affinity, yet creative.</li><li>Service oriented and communicative team player with passion for work.</li><li>Very good Excel and PowerPoint skills.</li><li>Outstanding oral and written communication skills.</li><li>Fluent in English, German is a plus.</li></ul>"
    imgSize="is-6-desktop is-5-tablet"
    txtSize="is-5-desktop is-6-tablet"
  />

  <section class="section">
    <div class="container">
      <div class="columns is-justify-content-space-between">
        <div class="column is-5">
          <h3 class="title">OMMAX in numbers</h3>
          <p>We are a digital strategy consultancy that specializes in the sustainable value creation for mid-sized companies, large enterprises, and investment firms worldwide.</p>
          <p>Our team of 250+ employees, based in Europe, has realized >800 projects in the digital field, such as strategy, operational excellence, data science, and transaction advisory services.</p>
          <p>We are proud to offer data-driven strategies and hands-on implementation support that builds digital strength, commercial growth potential, brand positioning, and improves the operational excellence of our clients.</p>
          <figure>
            <a href="https://www.kununu.com/de/ommax-digital-solutions" target="_blank">
              <Image imageSrc="kununu.png" imageAlt="OMMAX at Kununu" class="" width="200" />
            </a>
          </figure>
        </div>
        <div class="column is-6">
          <div class="columns is-mobile is-multiline">
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="250" suffix="+" :delay="0" content="Digital Experts" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="20" suffix="+" :delay="0.5" content="Languages spoken" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="50" suffix=":50" :delay="0" content="Male-Female Ratio" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="800" suffix="+" :delay="0.5" content="Digital Projects" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section has-background-white-ter" id="formTarget">
    <div class="container is-max-desktop">
      <h3 class="title">Upload application documents</h3>
      <Personio job_position_id="341749" recruiting_channel_id="550570" />
    </div>
  </section>
</main>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Subheader from '@/components/Subheader.vue'

import Textmedia from '@/components/Elements/Textmedia.vue'
import Image from '@/components/Elements/Image.vue'
import ImageTop from '@/components/Elements/ImageTop.vue'
import Quotation from '@/components/Elements/Quotation.vue'
import Box from '@/components/Elements/Box.vue'
import Countup from '@/components/Elements/Countup.vue'
import Personio from '@/components/Personio.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Subheader,
    Image,
    ImageTop,
    Textmedia,
    Quotation,
    Countup,
    Box,
    Personio
  }
}
</script>
