<template>
<div>
    <Image :imageSrc="imageSrc" :imageAlt="imageAlt" :class="imageClass" />
    <h5 class="title mb-4" v-html="headline" />
    <p v-html="content" class="mb-4" />
    </div>
</template>

<script>
// @ is an alias to /src
import Image from '@/components/Elements/Image.vue'

export default {
    name: 'ImageTop',
    components: {
        Image
    },
    props: {
        imageSrc: String,
        imageClass: String,
        imageAlt: String,
        content: String,
        headline: String
    }
}
</script>
