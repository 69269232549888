<template>
  <main class="page" id="top">
    <Header buttonText="Apply now" :buttonLink="careerLink" />
    <Subheader
      headline="BUILDING DIGITAL LEADERS &dash; JOIN OMMAX IN LONDON"
      text="Join the leading digital strategy consultancy in Europe in our London office"
      buttonText="FIND OUT MORE"
      scrollDownText="Get more information"
      image="life-at-ommax-4.jpg"/>

  <Textmedia
    headline="WHY JOIN OMMAX IN LONDON?"
    buttonText="Apply now"
    :buttonLink="careerLink"
    class=""
    image="london-business.jpg"
    imageAlt="OMMAX Andrea"
    content="<ul><li>Build in-depth expertise in digital, data and technology</li><li>Become a digital leader and build your digital capabilities with us</li><li>Work with leading private equity funds globally and C-level of large enterprises on digital strategy and digital business models</li><li>Gain various career opportunities: partner track, expert track, digital unit within corporate / SME and tech startup</li><li>Build leading e-com and software business models and invest in them</li><li>Be part of our growth story as architect and constructor of digital solutions</li><ul>"
    imgSize="is-4"
    txtSize="is-7"
    id="intro"
  />

	<section class="section has-background-blue has-signet" id="video">
    <span class="section--shadow-left"></span>
    <span class="section--signet"></span>
    <h4 class="title has-text-centered mb-6">ACCELERATE YOUR<br>DIGITAL CAREER</h4>
    <div class="container">
      <figure class="video">
        <video controls poster="@/assets/futureisnow-poster.jpg">
          <source src="@/assets/futureisnow.mp4" type="video/mp4">
        </video>
      </figure>
      <p class="has-text-centered mt-4">
        <a :href="careerLink" target="_blank" class="button is-text is-icon-arrow is-underlined pl-0 has-text-white mt-3">Apply now</a>
      </p>
    </div>
  </section>

  <Slider
    headline="OUR OFFICE IN 25 ECCLESTON PLACE"
    buttonText="Apply now"
    :buttonLink="careerLink"
    class=""
    imageAlt="OMMAX Andrea"
    content="<ul><li>Work in the heart of London, near West End</li><li>Co-work on the 1st floor of 25 Eccleston Place’s innovative workspace</li><li>Grow in a thriving environment along with young startups, investors and professional services providers</li><li>Be within the flourishing environment of Eccleston Yards, a community offering fitness, wellness and creative spaces, restaurants, and shops</li><li>Easily reach our office through great infrastructure: Victoria line tube, railway station and the regional bus station</li></ul>"
    imgSize="is-6"
    txtSize="is-5"
    id="where"
  />

  <section class="section is-large has-background-white-ter">
    <div class="container">
      <h3 class="title mb-6">LEARNING FROM OMMAX EXPERT TEAMS</h3>
      <div class="columns is-multiline">
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Advanced Data Analytics"
              class=""
              imageSrc="analytics.png"
              imageAlt="Advanced Data Analytics"
              imageClass="mb-4"
              content="Enabling data-driven decision making and performance optimization along the entire customer journey."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Content &amp; Marketing Automation"
              class=""
              imageSrc="automation.png"
              imageAlt="Content Marketing Automation"
              imageClass="mb-4"
              content="Personalizing and automating cross-channel customer journeys to optimize conversion, retention and share of wallet."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="E-Commerce &amp; Marketplaces"
              class=""
              imageSrc="ecommerce.png"
              imageAlt="E-Commerce Marketplaces"
              imageClass="mb-4"
              content="Developing best-in-class strategies around “how to (cross-) sell products / services online” and digital go-to-market."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Digital Acquisition"
              class=""
              imageSrc="acquisition.png"
              imageAlt="Digital Acquisition"
              imageClass="mb-4"
              content="Leveraging social media and online advertising to generate new leads and build long-lasting customer relationships."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Experience Design"
              class=""
              imageSrc="xd.png"
              imageAlt="Experience Design"
              imageClass="mb-4"
              content="Enhancing all elements of the customer experience from initial awareness to conversion and advocacy."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="Tech Development"
              class=""
              imageSrc="tech-development.png"
              imageAlt="Tech Development"
              imageClass="mb-4"
              content="Securing the foundation for all kinds of growth initiatives through tailored tech and app solutions."
            />
        </div>
      </div>
      <p class="mt-6">
        <a :href="careerLink" class="button px-5 is-primary has-text-blue is-icon-arrow" target="_blank">Apply now</a>
      </p>
    </div>
  </section>

	<Collage>
		<template #header>
			Life at OMMAX
		</template>
		<template #images>
      <div class="tile is-ancestor">
        <Image imageSrc="life-at-ommax-2.jpg" imageAlt="imageAlt" wrapClass="tile is-parent" />
        <Image imageSrc="life-at-ommax-4.jpg" imageAlt="imageAlt" wrapClass="tile is-parent" />
        <Image imageSrc="life-at-ommax-6.jpg" imageAlt="imageAlt" wrapClass="tile is-parent" />
      </div>
			<div class="tile is-ancestor">
				<Image imageSrc="life-at-ommax-5.jpg" imageAlt="imageAlt" wrapClass="tile is-3 is-parent" />
				<Image imageSrc="life-at-ommax.jpg" imageAlt="imageAlt" wrapClass="tile is-parent" />
        <Image imageSrc="life-at-ommax-3.jpg" imageAlt="imageAlt" wrapClass="tile is-3 is-parent" />
			</div>
			<div class="tile is-ancestor">
				<Image imageSrc="life-at-ommax-11.jpg" imageAlt="imageAlt" wrapClass="tile is-4 is-parent" />
				<Image imageSrc="life-at-ommax-10.jpg" imageAlt="imageAlt" wrapClass="tile is-parent" />
			</div>
		</template>
	</Collage>

  <section class="section is-large">

    <div class="container is-max-widescreen">
      <h2 class="title has-text-centered is-3 mb-6">OMMAX Voices</h2>
      <div class="columns is-7 is-variable">
        <div class="column is-6 has-text-centered">
            <Quotation
              headline=""
              class=""
              imageSrc="ommax-voices-12.jpg"
              imageAlt="OMMAX Voices"
              blockquote="“I joined OMMAX because of its digital capabilities and great team spirit.“"
              author="Alexander"
              subline="Senior Consultant"
            />
            <a href="https://www.linkedin.com/in/dr-alexander-r%C3%BChr-107a097b/" target="_blank" class="is-icon-linkedin mt-3"></a>
        </div>
        <div class="column is-6 has-text-centered">
            <Quotation
              headline=""
              class=""
              imageSrc="ommax-voices-16.jpg"
              imageAlt="OMMAX Voices"
              blockquote="“At OMMAX, I am able to build an international network of digital natives.“"
              author="Andrea"
              subline="Manager"
            />
            <a href="https://www.linkedin.com/in/andrea-maria-simon/" target="_blank" class="is-icon-linkedin mt-3"></a>
        </div>
      </div>
    </div>
  </section>

 <section class="section is-large has-background-white-ter">
    <div class="container">
      <h3 class="title mb-6">What makes us unique</h3>
      <div class="columns is-multiline is-variable is-6-desktop">
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="DIGITAL EXPERTISE"
              class=""
              imageSrc="expertise.png"
              imageAlt="DIGITAL EXPERTISE"
              imageClass="mb-4"
              content="Build in-depth expertise in digital, data and technology."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="LEADING CLIENTS"
              class=""
              imageSrc="clients.png"
              imageAlt="LEADING CLIENTS"
              imageClass="mb-4"
              content="Work with leading private equity firms globally and C-level of large enterprises and mid-size companies."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="INDIVIDUAL CAREERS"
              class=""
              imageSrc="careers.png"
              imageAlt="INDIVIDUAL CAREERS"
              imageClass="mb-4"
              content="Gain various career perspectives: partner track, expert track, digital unit within corporate / SME and tech startup."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="INVESTMENT OPPORTUNITIES"
              class=""
              imageSrc="investment.png"
              imageAlt="INVESTMENT OPPORTUNITIES"
              imageClass="mb-4"
              content="Co-invest in leading e-com and tech players and participate financially in digital value creation."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="DOWNTOWN CAMPUS"
              class=""
              imageSrc="campus.png"
              imageAlt="DOWNTOWN CAMPUS"
              imageClass="mb-4"
              content="Work 100% from the office in 25 Eccleston Place, London, and not Monday to Thursday at the client site. Possibility of working from home."
            />
        </div>
        <div class="column is-4-desktop is-6-tablet mt-3">
            <ImageTop
              headline="PROFESSIONAL TRAININGS"
              class=""
              imageSrc="trainings.png"
              imageAlt="PROFESSIONAL TRAININGS"
              imageClass="mb-4"
              content="Grow personally through various professional trainings by leading coaches."
            />
        </div>
      </div>
    </div>
  </section>

  <section class="section is-large">

    <div class="container is-max-widescreen">
      <h3 class="title has-text-centered is-3 mb-6">Exemplary project work</h3>
      <div class="columns has-border-between is-6 is-variable">
        <div class="column is-4">
            <ImageTop
              headline="Commercial Due Diligence"
              class=""
              imageSrc="perfect-dive.png"
              imageAlt="Commercial Due Diligence"
              imageClass="mb-6"
              content="Sale of Perfect Drives Sports Group by Afinum Management to Bregal.<br>&nbsp;"
            />
            <a href="https://www.ommax-digital.com/en/insights/newsroom/ommax-advised-afinum-on-the-successful-sale-of-perfect-drive-sports-group-to-bregal-unternehmerkapital/" target="_blank" class="button is-uppercase has-text-weight-bold is-underlined scrollTo is-text pl-0 has-text-blue">
              Read more
            </a>
        </div>
        <div class="column is-4">
            <ImageTop
              headline="Digital Strategy Development"
              class=""
              imageSrc="medifox.png"
              imageAlt="Digital Strategy Development"
              imageClass="mb-6"
              content="Development of digital marketing ecosystem and performance analytics platform"
            />
            <a href="https://www.ommax-digital.com/en/insights/newsroom/successful-collaboration-between-medifox-and-ommax/" target="_blank" class="button is-uppercase has-text-weight-bold is-underlined scrollTo is-text pl-0 has-text-blue">
              Read more
            </a>
        </div>
        <div class="column is-4">
            <ImageTop
              headline="Digital Due Diligence<br>&nbsp;"
              class=""
              imageSrc="pflegehelden.png"
              imageAlt="Digital Due Diligence"
              imageClass="mb-6"
              content="Acquisition of Plegehelden Group by Rigeto<br>&nbsp;"
            />
            <a href="https://www.ommax-digital.com/en/insights/newsroom/ommax-advised-group-of-investors-led-by-rigeto-unternehmerkapital-on-the-successful-acquisition-of-pflegehelden-group/" target="_blank" class="button is-uppercase has-text-weight-bold is-underlined scrollTo is-text pl-0 has-text-blue">
              Read more
            </a>
        </div>
      </div>
    </div>
  </section>

  <section class="section has-background-blue mb-6">
    <div class="container">
      <h3 class="title has-text-centered">Leading clients</h3>
      <div class="columns is-multiline is-mobile is-centered">
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="kkr-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="ai-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="l-catterton.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="oakley-capital.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <Image imageSrc="silverlake-logo.jpg" imageAlt="Leading Clients OMMAX" class="" />
        </div>
      </div>
    </div>
  </section>

  <Textmedia
    headline="WHAT WE LOOK FOR"
    class=""
    image="mba-responsible.jpg"
    imageAlt="OMMAX London Office - WHAT WE LOOK FOR"
    content="<ul><li>Analyze (digital) business models for notable Private Equity clients in an international context</li><li>Extensive management support in developing commercial / digital / tech / data due diligence reports for both buy- and sell-side transactions</li><li>Support in developing an equity story and (digital) value creation initiatives for portfolio companies of our private equity clients</li><li>Align and work on project requests and analyses closely with our internal digital expert teams</li><li>Prepare presentations for client projects and business development purposes</li><li>Identify client consulting needs, structure the problem, and assist in developing solutions</li><li>Co-responsibility for core activities such as workshops, meetings, research, business analysis and process development for both internal and external projects</li></ul>"
    imgSize="is-6-desktop is-5-tablet"
    txtSize="is-5-desktop is-6-tablet"
  />

  <Textmedia
    headline="What you can expect"
    class="has-background-white-ter"
    image="expect.jpg"
    imageAlt="OMMAX London Office - What you can expect"
    isImageRight
    content="<ul><li>Make a real difference and be a part of a growing, motivated and creative team directly collaborating with the OMMAX founders/partners</li><li>Benefit from a wide range of opportunities for personal and professional growth and a great learning atmosphere</li><li>Benefit from a variety of valuable experiences and insights into new digital business models and best practices across different industries</li><li>Take over responsibility for challenging and exciting projects and clients in an international environment</li><li>Enjoy food and beverages in the office, sport sessions and team events as well as other additional benefits</li><li>Receive a market competitive salary and bonus based on individual and company performance</li></ul>"
    imgSize="is-6-desktop is-5-tablet"
    txtSize="is-5-desktop is-6-tablet"
  />

  <Textmedia
    headline="What we require"
    buttonText="Apply now"
    :buttonLink="careerLink"
    class="mb-6"
    image="require.jpg"
    imageAlt="OMMAX London Office - What we require"
    content="<ul><li>University degree in business, economics, computer sciences or a related discipline with outstanding academic grades</li><li>Master degree (junior entry role) or at least 2-4 years of relevant experience (senior entry role) with focus on (digital) strategy consulting, transaction advisory or digital marketing</li><li>High analytical ability and technology affinity, yet creative</li><li>Service oriented and communicative team player with passion for work</li><li>Very good Excel and PowerPoint skills</li><li>Outstanding oral and written communication skills</li><li>Fluent in English</li></ul>"
    imgSize="is-6-desktop is-5-tablet"
    txtSize="is-5-desktop is-6-tablet"
  />

  <section class="section">
    <div class="container">
      <div class="columns is-justify-content-space-between">
        <div class="column is-5">
          <h3 class="title">OMMAX in numbers</h3>
          <p>We are a digital strategy consultancy that specializes in the sustainable value creation for mid-sized companies, large enterprises, and investment firms worldwide.</p>
          <p>Our team of 300+ employees, based in Europe, has realized >1500 projects in the digital field, such as strategy, operational excellence, data science, and transaction advisory services.</p>
          <p>We are proud to offer data-driven strategies and hands-on implementation support that builds digital strength, commercial growth potential, brand positioning, and improves the operational excellence of our clients.</p>
          <figure>
            <a href="https://www.kununu.com/de/ommax-digital-solutions" target="_blank">
              <Image imageSrc="kununu-en.png" imageAlt="OMMAX at Kununu" class="" width="200" />
            </a>
          </figure>
        </div>
        <div class="column is-6">
          <div class="columns is-mobile is-multiline">
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="300" suffix="+" :delay="0" content="Digital Experts" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="25" suffix="+" :delay="0.5" content="Languages spoken" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="50" suffix=":50" :delay="0" content="Male-Female Ratio" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="box">
                <div class="text has-text-centered">
                  <Countup :endVal="1500" suffix="+" :delay="0.5" content="Digital Projects" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Subheader from '@/components/Subheader.vue'
import Textmedia from '@/components/Elements/Textmedia.vue'
import Image from '@/components/Elements/Image.vue'
import ImageTop from '@/components/Elements/ImageTop.vue'
import Quotation from '@/components/Elements/Quotation.vue'
import Countup from '@/components/Elements/Countup.vue'
import Slider from '@/components/Elements/Slider.vue'
import Collage from '@/components/Elements/Collage.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Subheader,
    Image,
    ImageTop,
    Textmedia,
    Quotation,
    Countup,
    Slider,
		Collage
  },
  data() {
    return {
      careerLink: 'https://www.ommax-digital.com/en/career/#c2896'
    }
  },
}
</script>
