<template>
    <section class="section is-medium">
        <div class="container">
            <div class="columns is-justify-content-space-between is-vcentered" :class="isImageRight ? 'is-flex-direction-row-reverse' : ''">
                <div class="column" :class="imgSize">
                    <agile class="slider" :autoplay="true">
                        <div v-for="image in images" :key="image.src" class="slide">
                            <Image :imageSrc="image.src" imageAlt="imageAlt" class="textmedia" />
                        </div>
                        <template v-slot:prevButton class="asd">
                            <span class="is-icon-arrow">Previous</span>
                        </template>
                        <template v-slot:nextButton>
                            <span class="is-icon-arrow">Next</span></template>
                    </agile>
                </div>
                <div class="column" :class="txtSize">
                    <p class="title is-3" v-html="headline" />
                    <div v-html="content"></div>
                    <p v-if="buttonText">
                        <a v-if="buttonLink" :href="buttonLink" class="button mt-6 px-5 is-primary is-icon-arrow has-text-blue" target="_blank">{{buttonText}}</a>
                        <Button v-else :buttonText="buttonText" class="mt-6 px-5 is-primary is-icon-arrow has-text-blue" scrollTarget="#formTarget" />
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import Image from '@/components/Elements/Image.vue'
import Button from '@/components/Button.vue'

import { VueAgile } from 'vue-agile'


export default {
    name: 'Textmedia',
    components: {
        Button,
        Image,
        agile: VueAgile
    },
    props: {
        buttonText: String,
        buttonHref: String,
        buttonClass: String,
        imageAlt: String,
        content: String,
        headline: String,
        imgSize: String,
        txtSize: String,
        buttonLink: String,
        isImageRight: Boolean
    },
    data() {
        return {
            images: [
                {src: 'london-office-1.jpg'},
                {src: 'london-office-2.jpg'},
                {src: 'london-office-3.jpg'},
                {src: 'london-office-4.jpg'}
            ]
        }
    }
}
</script>

<style lang="scss">

@import "@/styles/misc/vars.scss";
@import "@/styles/elements/textmedia.scss";
@import "@/styles/elements/slider.scss";

</style>