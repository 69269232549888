import { createApp } from 'vue'
import VueLazyload from '@jambonn/vue-lazyload'
import App from './App.vue'
import router from './router'
import store from './store'

import Button from '@/components/Button.vue'

createApp(App)
.component('Button', Button)
.use(store)
.use(router)
.use(VueLazyload)
.mount('#app')
