<template>
    <button class="button is-uppercase has-text-weight-bold scrollTo" :data-anchor="scrollTarget" v-on:click="scrollTo">
        {{ buttonText }}
    </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    buttonText: String,
    scrollTarget: String
  },
  methods: {
    scrollTo: function () {
      var formTarget = this.$el.getAttribute('data-anchor'),
          anchor = document.querySelector(formTarget),
          y = anchor.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({top: y, behavior: 'smooth'});
    }

  }
}
</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/misc/button.scss";
</style>