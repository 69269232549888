<template>
    <figure>
        <a href="https://www.ommax-digital.com/en/career/" target="_blank">
            <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="260" height="50.748" viewBox="0 0 260 50.748">
                <g transform="translate(-68.044 -4023.698)">
                    <path id="Path_9" data-name="Path 9" d="M2236.722,4134.728h11.1l-19.143,40.863H2217.74Z" transform="translate(-1968.468 -101.669)" fill="#02e1ca"/>
                    <path id="Path_10" data-name="Path 10" d="M734.17,4052.3,720.6,4030.47H709.343v49.653H719.3v-33.938l14.72,22.344,14.865-22.561v34.156H759V4030.47H747.738Z" transform="translate(-587.235 -6.201)" fill="#fff"/>
                    <path id="Path_11" data-name="Path 11" d="M1440.7,4030.47l-22.592,47.3v-47.3h-11.258l-13.568,21.828-13.568-21.828h-11.259v49.653h9.961v-33.938l14.72,22.344L1408,4045.967v34.156h20.517l17.6-36.971,3.718,8.274,6-12.911-3.976-8.046Z" transform="translate(-1190.777 -6.201)" fill="#fff"/>
                    <path id="Path_12" data-name="Path 12" d="M2484.864,4054.629l16.391-24.253h-11.671l-10.664,16.56-10.592-16.56H2456.3l16.392,24.4-15.773,23.3-9.11-19.372-5.7,12.172,4.123,9.164,21.034-.005,11.373-17.49,11.3,17.49h12.027Z" transform="translate(-2173.921 -6.115)" fill="#fff"/>
                    <path id="Path_13" data-name="Path 13" d="M101.433,4025.006a25.371,25.371,0,1,0,16.045,32.085,25.39,25.39,0,0,0-16.045-32.085m7.048,29.087a15.882,15.882,0,1,1-.862-12.127,15.862,15.862,0,0,1,.862,12.127" transform="translate(0 0)" fill="#fff"/>
                </g>
            </svg>
        </a>
    </figure>
</template>
<script>
    export default {
        name: 'Logo'     
    }    
</script>
