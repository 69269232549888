<template>
<!-- <Countup
endVal="160"
content="Male-Female Ratio"
delay="1000"
separator=","
suffix="%"  /> -->

<transition
      appear
      @enter="enter">
      <div class="countup" :data-countup-end="endVal" :data-countup-delay="delay">
        <p class="title is-3 mb-1" :data-suffix="suffix">0</p>
        <p v-html="content" />
      </div>
      
    </transition>

</template>

<script>
  import { gsap } from 'gsap';
  import { ScrollTrigger } from 'gsap/ScrollTrigger';
  gsap.registerPlugin(ScrollTrigger);

  export default {
    name: 'Countup',
    props: {
      content: String,
      endVal: Number,
      delay: Number,
      suffix: String
    },
    /* eslint-disable no-unused-vars */
    setup() {
      const enter = (el) => {
        
        let startCount = 0,
            num = {var:startCount};

        gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: "top bottom",
              markers: false
            }
          }).to(
            num, {
              var: el.getAttribute('data-countup-end'),
              duration: 0.5,
              delay: el.getAttribute('data-countup-delay'), 
              ease:"none",
              onUpdate:function(){
                el.childNodes[0].innerHTML = (num.var).toFixed();
              }
            }
          )
      }
      return { enter };
    } 
    /* eslint-enable no-unused-vars */
  };

</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/elements/countup.scss";
</style>
