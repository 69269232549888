<template>
    <figure :class="wrapClass">
       <img v-lazy="require(`@/assets/${imageSrc}`)" :alt="imageAlt">
    </figure>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Image',
    components: {
    },
    props: {
        imageSrc: String,
        imageAlt: String,
        wrapClass: String
    }
}
</script>

<style lang="scss">
@import "@/styles/misc/vars.scss";
@import "@/styles/misc/image.scss";
</style>